import languages from "./index"
import { colors } from "../utils/styles"

const langs = languages.map(o => {
  // TODO memoize this
  return {
    name: o.meta.name,
    code: o.meta.code,
    path: o.path,
    color: o.meta.color,
    special: o.meta.special,
    hasAudio: o.meta.audio,
  }
})

function getLang(location) {
  const path = location.replace(/\//g, "")
  const match = langs.find(l => l.path === path)

  function themeColor() {
    if (match) {
      return match.color
    }

    switch (path) {
      case "":
        return `rgba(255,255,255,0)`
      default:
        return colors.brand
    }
  }

  return {
    name: match ? match.name : "Language",
    color: themeColor(),
    gradient: match ? match.gradient : "blue",
    special: match ? match.special : false,
    hasAudio: match ? match.hasAudio : false,
  }
}

const credits = [
  // Libraries, APIs, Services
  { name: `Gatsby`, url: `https://www.gatsbyjs.org/`, type: `OSS` },
  { name: `React Spring`, url: `https://www.react-spring.io/`, type: `OSS` },
  { name: `LinguiJS`, url: `https://lingui.js.org/`, type: `OSS` },
  { name: `Emotion`, url: `https://emotion.sh/`, type: `OSS` },
  {
    name: `Speech Synthesis API`,
    url: `https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesis`,
    type: `API`,
  },
  {
    name: `Airtable`,
    url: `https://airtable.com/`,
    type: `API`,
  },
  // Contributors
  { name: `Giordana Pais`, url: ``, type: `Contributor` },
  { name: `Jenah Mayzes`, url: ``, type: `Contributor` },
  { name: `Lana Dalle`, url: ``, type: `Contributor` },
  { name: `Kim Ruff`, url: ``, type: `Contributor` },
  { name: `Kaman Wan`, url: ``, type: `Contributor` },
  { name: `Sophie Van Der Meulen`, url: ``, type: `Contributor` },
  { name: `Rebecca Diego`, url: ``, type: `Contributor` },
]

export { langs, getLang, credits }
