import React from "react"
import PropTypes from "prop-types"
import { animated as a, useTransition } from "react-spring"
import styled from "@emotion/styled"

const ModalMenu = ({ toggle, position, padding, classes, children }) => {
  const transitions = useTransition(toggle, null, {
    from: { position: "absolute", opacity: 0, transform: `translateY(-10px)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0, transform: `translateY(-10px)` },
    config: { friction: 20 },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Modal
              key={key}
              style={props}
              position={position}
              padding={padding}
              className={classes}
            >
              {children}
            </Modal>
          )
      )}
    </>
  )
}

const Modal = styled(a.div)`
  top: ${({ position }) => position.top}px;
  bottom: ${({ position }) => position.bottom}px;
  right: ${({ position }) => position.right}px;
  padding: ${({ padding }) => padding};
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  color: #fff;
  z-index: 100;
`

Modal.propTypes = {
  toggle: PropTypes.bool,
  position: PropTypes.object,
  padding: PropTypes.string,
  classes: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Modal.defaultProps = {
  toggle: null,
  position: {},
  padding: ``,
  classes: ``,
}

export default ModalMenu
