import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 200
const getTransitionStyles = {
  entering: {
    position: "absolute",
    opacity: 0,
    transform: `translateY(-10px)`,
  },
  entered: {
    transition: `all ${400}ms cubic-bezier(.38, .92, .66, .82)`,
    opacity: 1,
  },
  exiting: {
    transition: `all ${timeout}ms cubic-bezier(.38, .92, .66, .82)`,
    opacity: 0,
    transform: `translateY(10px)`,
  },
}

class PageTransition extends React.PureComponent {
  render() {
    const { location, children } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.key}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          {status => (
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default PageTransition
