import React, { useState, useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"

import Modal from "../Modal"
import { UIContext } from "../../context/UIContext"
import { langs } from "../../data/snippets"

const CurrentLang = ({ language }) => {
  const { state } = useContext(UIContext)
  const buttonRef = useRef(null)
  const [show, set] = useState(false)

  useEffect(() => {
    if (show) {
      document.addEventListener(`mousedown`, handleClick)
    }
    return () => document.removeEventListener(`mousedown`, handleClick)
  }, [show])

  const handleClick = e => {
    if (buttonRef.current.contains(e.target)) {
      return
    }

    return set(false)
  }

  return (
    <ListItem style={{ padding: "20px", margin: 0 }}>
      <ToggleLang onClick={() => set(!show)} ref={buttonRef}>
        <Trans id={language.name === "Language" ? "Select" : language.name} />{" "}
        <span aria-hidden="true">&#9662;</span>
      </ToggleLang>
      <Modal toggle={show} padding="30px" position={{ top: 75, right: 110 }}>
        <h4>
          <Trans>All Guides</Trans>
        </h4>
        <ul>
          {langs
            .filter(l => l.code !== state.baseLang.code)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(l => (
              <li key={l.name}>
                <Link
                  to={`/${l.name.toLowerCase()}`}
                  onClick={() => set(false)}
                >
                  <Trans id={l.name} />
                </Link>
              </li>
            ))}
        </ul>
      </Modal>
    </ListItem>
  )
}

const ListItem = styled.li`
  padding: 20px;
  margin: 0;

  &:hover button {
    background: rgba(255, 255, 255, 0.1);
  }

  h4 {
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
  }

  ul {
    columns: 4;
    margin: 0;
    list-style-type: none;
    column-gap: 35px;
    font-size: 16px;
  }
`

const ToggleLang = styled.button`
  color: white;
  border-radius: 3px;
  padding: 10px 15px 10px 22px;
  letter-spacing: 0.2px;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.09);
  transition: background ease-out 0.35s;
`

CurrentLang.propTypes = {
  language: PropTypes.object,
}

CurrentLang.defaultProps = {
  language: {},
}

export default CurrentLang
