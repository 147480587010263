import React from "react"

const InfoIcon = ({ setInfo, showInfo, ...props }) => (
  <button
    {...props}
    className="naked-btn"
    onMouseEnter={() => setInfo(true)}
    onMouseLeave={() => setInfo(false)}
    onClick={() => setInfo(!showInfo)}
    style={{ display: "flex" }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-labelledby="svgtitle"
      style={{ margin: 0 }}
    >
      <title id="svgtitle">Show website status</title>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </svg>
  </button>
)

export default InfoIcon
