import React, { useContext } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { Trans } from "@lingui/macro"

import { UIContext } from "../../context/UIContext"
import { langs } from "../../data/snippets"
import { fonts, breakpoints } from "../../utils/styles"

const LanguageList = ({ color, type, exit }) => {
  const { state } = useContext(UIContext)

  return (
    <List color={color} type={type}>
      {langs
        .filter(l => l.code !== state.baseLang.code)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(lang => (
          <li key={lang.code}>
            <Link
              to={`/${lang.name.toLowerCase()}`}
              onClick={() => (exit ? exit() : null)}
            >
              <Code className="l-tile" color={color}>
                {lang.code}
              </Code>
              <span className="language">
                <Trans id={lang.name} />
              </span>
            </Link>
          </li>
        ))}
    </List>
  )
}

const mobilePartial = ({ type }) => {
  if (type === "mobile") {
    return css`
      a {
        color: #fff;
      }
    `
  }

  return css`
    a {
      color: #101010;
    }
  `
}

const List = styled.ul`
  list-style-type: none;
  line-height: 2.5;
  column-count: 2;
  max-width: 1200px;
  margin: auto;
  ${mobilePartial}

  a {
    font-size: 15px;
  }

  a:hover .l-tile,
  a:focus .l-tile,
  a:active .l-tile {
    color: #fff;
    border-color: ${({ color }) => color};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    column-count: 4;

    a {
      font-size: 16px;
      padding: 20px 0;
    }

    a:hover .l-tile,
    a:focus .l-tile,
    a:active .l-tile {
      background: ${({ color }) => color};
    }

    li {
      padding-left: 40px;
    }
  }
`

const Code = styled.span`
  border: 2px solid;
  padding: 5px;
  border-radius: 3px;
  height: 32px;
  width: 32px;
  font-size: 17px;
  display: inline-flex;
  color: ${({ color }) => color};
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-family: ${fonts.heading};
  font-weight: bold;
  transition: all ease 0.3s;
`

LanguageList.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  exit: PropTypes.func,
}

LanguageList.defaultProps = {
  color: ``,
  type: ``,
  exit: () => {},
}

export default LanguageList
