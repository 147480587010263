import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Trans } from "@lingui/macro"

import { radius, fonts, breakpoints } from "../../utils/styles"
import { KeyboardIcon } from "../../assets/icons"
import AudioButton from "../AudioButton"

const Shortcuts = props => {
  const {
    hasAudio,
    active,
    setActive,
    audio,
    setAudio,
    language,
    string,
  } = props
  return (
    <HelpWrapper>
      <KeyboardHelp>
        <HelpButton active={active} onClick={() => setActive(!active)}>
          <KeyboardIcon />
        </HelpButton>
        <div style={{ overflow: "hidden" }}>
          <KeyList active={active}>
            <ListItem>
              <strong>Space</strong> <strong>&#8593;</strong>{" "}
              <strong>&#8595;</strong> <Trans>Flip Card</Trans>
            </ListItem>
            <ListItem>
              <strong>&#8592;</strong> <strong>&#8594;</strong>{" "}
              <Trans>Prev / Next</Trans>
            </ListItem>
            <ListItem>
              <strong>S</strong> <Trans>Shuffle</Trans>
            </ListItem>
            <ListItem>
              <strong>L</strong> <Trans>Listen</Trans>
            </ListItem>
            <ListItem>
              <strong>esc</strong> <Trans>Close</Trans>
            </ListItem>
          </KeyList>
        </div>
      </KeyboardHelp>

      {hasAudio ? (
        <AudioHelper>
          <AudioButton
            string={string}
            language={language}
            audio={audio}
            setAudio={setAudio}
          />
        </AudioHelper>
      ) : null}
    </HelpWrapper>
  )
}

// Styled Components

const HelpWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
`

const KeyboardHelp = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

const HelpButton = styled.button`
  background: none;
  border: 2px solid;
  border-color: ${({ active }) =>
    active ? `rgba(255, 255, 255, 0);` : `rgba(255, 255, 255, 0.13);`}
  border-radius: ${radius.large}px;
  display: flex;
  margin: 0 30px 0 0;
  overflow: visible;
  height: 60px;
  width: 60px;
  padding: 15px;
  transition: all ease 0.4s;

  svg {
    fill: #fff;
    height: 17px;
    overflow: hidden;
    transition: all ease 0.4s;
  }
`

const listPartial = ({ active }) =>
  active
    ? css`
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
      `
    : css`
        opacity: 0;
        transform: translateX(-30px);
        visibility: hidden;
      `

const KeyList = styled.ul`
  column-count: 3;
  column-gap: 30px;
  margin: 0;
  transition: all 0.5s ease;
  ${listPartial}
`
const ListItem = styled.li`
  list-style-type: none;
  font-family: ${fonts.heading};

  &:last-child {
    margin-left: -75px;
  }

  strong {
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 10px;
    padding: 2px 5px;
    margin: 0 1px;
    border-radius: 2px;
  }

  strong:last-child {
    margin-right: 5px;
  }
`

const AudioHelper = styled.div`
  width: 100%;

  button {
    background: none;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100px;
    margin: auto;
    border: 2px solid rgba(255, 255, 255, 0.13);
    border-radius: ${radius.large}px;
  }

  svg path {
    fill: #fff;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 60px;
    button {
      width: 60px;
    }
  }
`

Shortcuts.propTypes = {
  hasAudio: PropTypes.bool,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  audio: PropTypes.bool,
  setAudio: PropTypes.func,
  language: PropTypes.string,
  string: PropTypes.string,
}

Shortcuts.defaultProps = {
  hasAudio: true,
  active: true,
  setActive: () => {},
  audio: false,
  setAudio: () => {},
  language: ``,
  string: ``,
}

export default Shortcuts
