import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { t, Trans } from "@lingui/macro"
import { useSpring, animated as a } from "react-spring"

import Logo from "./Logo"
import Switcher from "../Switcher"
import MobileNav from "./MobileNav"
import CurrentLang from "./CurrentLang"
import { HamburgerIcon } from "../../assets/icons"
import { fonts, breakpoints, dimensions, colors } from "../../utils/styles"

const links = [`About`, `Feedback`]

const Header = React.memo(({ language, location, prevLang }) => {
  const [showMobile, setMobile] = useState(false)
  const [title, setTitle] = useState(language.name)

  useEffect(() => {
    document.body.classList.add("refresh")
    setTimeout(() => {
      setTitle(language.name)
      document.body.classList.remove("refresh")
    }, 700)
  }, [language.name])

  const bgTransition = useSpring({
    from: {
      background: prevLang.color || `rgba(255,255,255,0)`,
    },
    to: {
      background: language.color || colors.brand,
    },
  })

  return (
    <>
      <Navbar>
        <SiteHeader style={bgTransition}>
          <Link to="/" className="logo">
            <LogoWrapper>
              <Logo />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  zIndex: "1",
                }}
              >
                <TextWrapper className="logo-text">
                  <span>
                    <Trans id={title} />
                  </span>
                  <span>
                    <Trans id={t`Guide`} />
                  </span>
                </TextWrapper>
              </div>
            </LogoWrapper>
          </Link>

          <DesktopNav>
            <NavMenu role="menu" aria-label="Main Navigation">
              {links.map(link => (
                <NavItem key={link}>
                  <Link to={`/${link.toLowerCase()}`}>
                    <Trans id={link} />
                  </Link>
                </NavItem>
              ))}
              <CurrentLang language={language} />
            </NavMenu>
            <Switcher />
          </DesktopNav>

          <TriggerMenu
            className="naked-btn"
            location={location}
            aria-pressed={showMobile ? "true" : "false"}
            aria-haspopup="true"
            onClick={() => setMobile(true)}
          >
            <HamburgerIcon />
          </TriggerMenu>
          <MobileNav show={showMobile} setMobile={setMobile} />
        </SiteHeader>
      </Navbar>
    </>
  )
})

// Styled Components

const TriggerMenu = styled.button`
  z-index: 10;
  height: 100%;
  border-left: ${({ location }) =>
    location !== "/" ? "1px solid rgba(255, 255, 255, 0.15)" : null};
  padding: 0 18px;

  svg {
    height: 32px;
    fill: #fff;
  }
  @media screen and (min-width: ${breakpoints.desktopSM}px) {
    display: none;
  }
`

const Navbar = styled.header`
  position: relative;
  z-index: 100;
  height: ${dimensions.header.mobile}px;

  a {
    color: #fff;
  }

  @media (min-width: ${breakpoints.desktopSM}px) {
    height: ${dimensions.header.desktop}px;
  }
`

const SiteHeader = styled(a.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 0 0 1rem;

  .logo {
    display: block;
  }

  @media (min-width: ${breakpoints.desktopSM}px) {
    padding: 0 2rem 0.1rem;
  }
`

const DesktopNav = styled.nav`
  z-index: 10;
  display: flex;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

const NavMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  font-size: 18px;
  font-family: ${fonts.heading};
`

const NavItem = styled.li`
  padding: 30px;
  margin: 0;
  position: relative;
`

const TextWrapper = styled.div`
  // position: absolute;
  font-size: 26px;
  margin: 4px 0 0 15px;
  font-family: ${fonts.heading};

  span:first-of-type {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 36px;
    margin: 2px 0 0 25px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 30px;
    overflow: initial;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    svg {
      height: 40px;
    }
  }
`

Header.propTypes = {
  language: PropTypes.object,
  prevLang: PropTypes.object,
  location: PropTypes.string,
}

Header.defaultProps = {
  language: {},
  prevLang: {},
  location: ``,
}

export default Header
