import React from "react"

const KeyboardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.14 63.68">
    <path d="M104.06 2.07a6.82 6.82 0 0 0-5-2.07h-92a6.84 6.84 0 0 0-5 2.07A6.84 6.84 0 0 0 0 7.08v49.53a6.82 6.82 0 0 0 2.07 5 6.84 6.84 0 0 0 5 2.07h92a7.08 7.08 0 0 0 7.08-7.07V7.08a6.85 6.85 0 0 0-2.09-5.01zm-5 54.54h-92V7.08h92z" />
    <path d="M15 49.53h5.3a.79.79 0 0 0 .89-.89v-5.3a.79.79 0 0 0-.89-.89H15a.79.79 0 0 0-.89.89v5.3a.79.79 0 0 0 .89.89zM15 35.38h12.42a.78.78 0 0 0 .88-.89v-5.3a.78.78 0 0 0-.88-.89H15a.79.79 0 0 0-.89.89v5.3a.79.79 0 0 0 .89.89zM15 21.23h5.3a.79.79 0 0 0 .89-.89V15a.79.79 0 0 0-.89-.89H15a.79.79 0 0 0-.89.89v5.3a.79.79 0 0 0 .89.93zM77 42.45H29.19a.79.79 0 0 0-.89.89v5.31a.79.79 0 0 0 .89.88H77a.78.78 0 0 0 .88-.88v-5.31a.78.78 0 0 0-.88-.89zM35.38 34.49a.78.78 0 0 0 .88.89h5.31a.78.78 0 0 0 .88-.89v-5.3a.78.78 0 0 0-.88-.89h-5.31a.78.78 0 0 0-.88.89v5.3zM29.19 21.23h5.3a.79.79 0 0 0 .89-.89V15a.79.79 0 0 0-.89-.89h-5.3a.79.79 0 0 0-.89.89v5.3a.79.79 0 0 0 .89.93zM49.53 34.49a.78.78 0 0 0 .88.89h5.31a.79.79 0 0 0 .89-.89v-5.3a.79.79 0 0 0-.89-.89h-5.31a.78.78 0 0 0-.88.89v5.3zM43.34 21.23h5.3a.79.79 0 0 0 .89-.89V15a.79.79 0 0 0-.89-.89h-5.3a.79.79 0 0 0-.89.89v5.3a.79.79 0 0 0 .89.93zM63.68 34.49a.79.79 0 0 0 .88.89h5.31a.79.79 0 0 0 .89-.89v-5.3a.79.79 0 0 0-.89-.89h-5.31a.79.79 0 0 0-.88.89zM91.1 42.45h-5.31a.78.78 0 0 0-.88.89v5.31a.78.78 0 0 0 .88.88h5.31a.78.78 0 0 0 .88-.88v-5.31a.78.78 0 0 0-.88-.89zM57.49 21.23h5.31a.78.78 0 0 0 .88-.89V15a.78.78 0 0 0-.88-.89h-5.31a.78.78 0 0 0-.88.89v5.3a.78.78 0 0 0 .88.93zM71.64 21.23H77a.78.78 0 0 0 .88-.89V15a.78.78 0 0 0-.88-.89h-5.36a.78.78 0 0 0-.88.89v5.3a.78.78 0 0 0 .88.93zM77.83 34.49a.79.79 0 0 0 .89.89H91.1a.78.78 0 0 0 .88-.89V15a.78.78 0 0 0-.88-.89h-5.31a.78.78 0 0 0-.88.89v13.3h-6.19a.79.79 0 0 0-.89.89v5.3z" />
  </svg>
)

export default KeyboardIcon
