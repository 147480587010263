import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { animated as a, useTransition } from "react-spring"
import styled from "@emotion/styled"

import { UIContext } from "../../context/UIContext"

const LangModal = ({ code }) => {
  const { state } = useContext(UIContext)
  const [baseLang, setLang] = useState(state.baseLang.code)
  const [active, set] = useState(false)

  useEffect(() => {
    if (code !== baseLang) {
      set(true)
      setLang(code)
      setTimeout(() => {
        set(false)
      }, 500)
    }
  }, [code])

  const transitions = useTransition(active, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <LanguageCode>{code}</LanguageCode>
            </Container>
          )
      )}
    </>
  )
}

const Container = styled(a.div)`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LanguageCode = styled.div`
  border-radius: 5%;
  height: 50vh;
  width: 50vh;
  background: rgba(0, 0, 0, 0.65);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 7em;
`

LangModal.propTypes = {
  code: PropTypes.string,
}

LangModal.defaultProps = {
  code: `en`,
}

export default LangModal
