const French = require("./languages/french.json")
const Spanish = require("./languages/spanish.json")
const Italian = require("./languages/italian.json")
const Portuguese = require("./languages/portuguese.json")
const German = require("./languages/german.json")
const Dutch = require("./languages/dutch.json")
const Chinese = require("./languages/chinese.json")
const Japanese = require("./languages/japanese.json")
const Arabic = require("./languages/arabic.json")
const Croatian = require("./languages/croatian.json")
const Czech = require("./languages/czech.json")
const Danish = require("./languages/danish.json")
const Finnish = require("./languages/finnish.json")
const Greek = require("./languages/greek.json")
const Hebrew = require("./languages/hebrew.json")
const English = require("./languages/english.json")
const Hindi = require("./languages/hindi.json")
const Hungarian = require("./languages/hungarian.json")
const Indonesian = require("./languages/indonesian.json")
const Korean = require("./languages/korean.json")
const Norwegian = require("./languages/norwegian.json")
const Polish = require("./languages/polish.json")
const Romanian = require("./languages/romanian.json")
const Russian = require("./languages/russian.json")
const Serbian = require("./languages/serbian.json")
const Swahili = require("./languages/swahili.json")
const Swedish = require("./languages/swedish.json")
// const Tamil = require("./languages/tamil.json")
// const Thai = require("./languages/thai.json")
const Turkish = require("./languages/turkish.json")
const Ukrainian = require("./languages/ukrainian.json")
const Vietnamese = require("./languages/vietnamese.json")
const Filipino = require("./languages/filipino.json")

module.exports = [
  French,
  Spanish,
  Italian,
  Portuguese,
  German,
  Dutch,
  Chinese,
  Japanese,
  Arabic,
  Croatian,
  Czech,
  Danish,
  Finnish,
  English,
  Swedish,
  Greek,
  Hebrew,
  Hindi,
  Hungarian,
  Indonesian,
  Korean,
  Norwegian,
  Polish,
  Romanian,
  Russian,
  Serbian,
  Swahili,
  // Tamil,
  // Thai,
  Turkish,
  Ukrainian,
  Vietnamese,
  Filipino,
]
