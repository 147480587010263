import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { updateUI } from "../../context/UIContext"

const languageArray = [
  { name: "English", code: "en" },
  { name: "中文", code: "zh" },
]

const SwitchMenu = ({ baseLang, closeModal }) => {
  const { toggleBaseLang } = updateUI()

  return (
    <SwitchList>
      {languageArray.map(lang => (
        <li key={lang.code}>
          <button
            aria-label={`View website in ${lang.name}`}
            className={`naked-btn ${
              baseLang.code === lang.code ? "active" : ""
            }`}
            onClick={() => {
              toggleBaseLang({ name: lang.name, code: lang.code })
              closeModal(false)
            }}
          >
            <span aria-hidden="true">{lang.code}</span> {lang.name}
          </button>
        </li>
      ))}
    </SwitchList>
  )
}

const SwitchList = styled.ul`
  margin: 0;
  list-style-type: none;
  font-size: 16px;

  button {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    transition: all ease 0.3s;
  }

  button:hover,
  button.active {
    color: #fff;

    span {
      border-color: #fff;
    }
  }

  li:last-of-type {
    margin: 0;
  }

  span {
    height: 25px;
    width: 25px;
    font-size: 0.85em;
    margin-right: 10px;
    border: 1px solid rgba(255, 255, 255, 0.35);
    display: flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;
    transition: all ease 0.3s;
  }
`

SwitchMenu.propTypes = {
  baseLang: PropTypes.object,
}

SwitchMenu.defaultProps = {
  baseLang: {},
}

export default SwitchMenu
