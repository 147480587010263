import { keyframes, css } from "@emotion/core"

export const colors = {
  brandDark: "#032f8e",
  brand: "#1242ab",
  brandLight: "#2960da",
  text: "#333333",
  accent: `#ffb238`,
  error: `#ec1818`,
}

export const spacing = {
  "3xs": 2,
  "2xs": 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  "2xl": 40,
  "3xl": 48,
}

export const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 750,
  desktopSM: 960,
  desktopMD: 1000,
  desktopLG: 1300,
}

export const radius = {
  default: 3,
  large: 5,
}

export const defaultFontStack = [
  "Open Sans",
  "Helvetica Neue",
  "sans-serif",
].join()

const monospaceFontStack = [
  `Space Mono`,
  `SFMono-Regular`,
  `Menlo`,
  `Monaco`,
  `Consolas`,
  `Liberation Mono`,
  `Courier New`,
  `monospace`,
].join()

export const fonts = {
  body: `Noto Sans, ${defaultFontStack}`,
  heading: `Avenir Next, ${defaultFontStack}`,
  monospace: monospaceFontStack,
}

export const globalStyles = css`
  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
    font-family: Noto Sans, Open Sans, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.375;
    margin: 0 auto;
  }

  .logo-text {
    transition: all ease 0.35s;
  }

  .refresh .logo-text {
    opacity: 0;
    transform: translateX(10px);
  }

  .naked-btn {
    background: none;
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
  }

  a {
    color: ${colors.brand};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: ${fonts.heading};
  }
`

export const dimensions = {
  header: {
    mobile: 70,
    desktop: 110,
  },
  studyGuideModal: 350,
  bounds: 1140,
}

export const simpleEntry = keyframes`
  from {
    transform: translateY(20px);
  }
`
