import React from "react"
import PropTypes from "prop-types"
import { useSpring, animated as a } from "react-spring"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Trans } from "@lingui/macro"

import { fonts, breakpoints } from "../../utils/styles"

const Card = props => {
  const { effect, data, baseLang, flipped, setFlip, language } = props

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  function renderCardFace(translation) {
    if (language.special) {
      return (
        <>
          <div>{translation.characters}</div>
          <div className="romanized">{translation.romanization}</div>
        </>
      )
    }
    return <div>{translation.romanization}</div>
  }

  return (
    <CardWrapper onClick={() => setFlip()} color={language.color}>
      <FlashCard shift={effect}>
        <CardFace
          style={{ opacity: opacity.interpolate(o => 1 - o), transform }}
        >
          <CardHeading color={language.color} side="front">
            <Trans id={language.name} />
          </CardHeading>
          <CardTerm>
            <strong>{renderCardFace(data.translation)}</strong>
          </CardTerm>
        </CardFace>
        <CardFace
          style={{
            opacity,
            transform: transform.interpolate(t => `${t} rotateX(180deg)`),
          }}
        >
          <CardHeading color={language.color} side="back">
            <Trans id={baseLang} />
          </CardHeading>
          <CardTerm>
            <Trans id={data.base} />
          </CardTerm>
        </CardFace>
      </FlashCard>
    </CardWrapper>
  )
}

// Styled Components

const CardWrapper = styled.div`
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  margin-top: 30px;
  font-family: ${fonts.heading};

  @media (min-width: ${breakpoints.tablet}px) {
    align-items: center;
    margin: 0;
  }
`

const headingPartial = ({ side, color }) => {
  if (side === "front") {
    return css`
      color: #fff;
      background: ${color};
    `
  }
  return css`
    border-bottom: 1px solid #e2e2e2;
    background: #fbfbfb;
    color: ${color};
  `
}

const CardHeading = styled.div`
  position: absolute;
  top: 0;
  padding: 15px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  width: 100%;
  text-align: center;
  ${headingPartial}

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 20px;
  }
`

const CardTerm = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  padding-top: 20px;
  text-align: center;

  .romanized {
    font-size: 0.7em;
    margin: 5px 0 -20px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 38px;
    padding-top: 0;
  }
`

const CardFace = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
`

const cardPartial = ({ shift }) => {
  switch (shift) {
    case "next":
      return css`
        transform: translateX(20px);
      `
    case "prev":
      return css`
        transform: translateX(-20px);
      `
    case "shuffle":
      return css`
        transform: translateY(30px);
      `
    default:
      return
  }
}

const FlashCard = styled.div`
  width: 100%;
  height: 100%;
  max-width: 80%;
  max-height: 33%;
  position: absolute;
  transition: all ease 0.3s;
  ${cardPartial}

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 500px;
    max-height: 300px;
  }
`

Card.propTypes = {
  language: PropTypes.object,
  effect: PropTypes.string,
  flipped: PropTypes.bool,
  setFlip: PropTypes.func,
  data: PropTypes.object,
  baseLang: PropTypes.string,
}

Card.defaultProps = {
  language: {},
  effect: ``,
  flipped: false,
  setFlip: () => {},
  data: {},
  baseLang: ``,
}

export default Card
