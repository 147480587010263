import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import SwitchMenu from "./SwitchMenu"
import Modal from "../Modal"
import { LanguageIcon } from "../../assets/icons"
import { UIContext } from "../../context/UIContext"

const Switcher = ({ type }) => {
  const { state } = useContext(UIContext)
  const [show, set] = useState(false)

  return (
    <LangSwitch type={type}>
      <button aria-label="Change Site Language" onClick={() => set(!show)}>
        <LanguageIcon />
        <div>{state.baseLang.code.toUpperCase()} &#9662;</div>
      </button>
      <Modal
        padding="20px 15px"
        position={{ right: -10, top: 65 }}
        toggle={show}
        classes="switcher-modal"
      >
        <SwitchMenu baseLang={state.baseLang} closeModal={set} />
      </Modal>
    </LangSwitch>
  )
}

const switchPartial = ({ type }) => {
  if (type === "mobile") {
    return css`
      position: absolute;
      top: 15px;
      left: 0;
    `
  }
}

const modalPartial = ({ type }) => {
  if (type === "mobile") {
    return css`
      border: 1px solid rgba(255, 255, 255, 0.16);
      background: rgba(0, 0, 0, 0.31);
      right: -30px;
      top: 55px;
      padding: 15px;
    `
  }
}

const LangSwitch = styled.div`
  padding: 20px;
  font-size: 16px;
  margin: 0;
  display: flex;
  position: relative;
  margin-left: 20px;
  ${switchPartial}

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-weight: bold;
    color: #fff;
    padding: 0;

  }

  svg {
    fill #fff;
    height: 24px;
    margin-right: 5px;
  }

  .switcher-modal {
    ${modalPartial}
  }
`

Switcher.propTypes = {
  type: PropTypes.string,
}

Switcher.defaultProps = {
  type: ``,
}

export default Switcher
