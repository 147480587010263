import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { DirectionIcon } from "../../assets/icons"
import { breakpoints, radius } from "../../utils/styles"

const Controls = ({ update, effect }) => {
  const activePrev = effect === "prev" || effect === "shuffle" ? true : false
  const activeNext = effect === "next" || effect === "shuffle" ? true : false

  return (
    <ControlWrapper>
      <Control
        direction="prev"
        onClick={() => update("prev")}
        active={activePrev}
      >
        <DirectionIcon direction="left" />
      </Control>
      <Control
        direction="next"
        onClick={() => update("next")}
        active={activeNext}
      >
        <DirectionIcon direction="right" />
      </Control>
    </ControlWrapper>
  )
}

// Styled Components

const ControlWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 120px;
  right: 0;
  left: 0;
  padding: 0 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}px) {
    bottom: 0;
    align-items: center;
  }
`

const activePartial = ({ active }) =>
  active
    ? css`
        background: rgba(255, 255, 255, 0.15);

        svg {
          fill: rgba(0, 0, 0, 0.3);
        }
      `
    : css`
        background: rgba(255, 255, 255, 0);
        svg {
          fill: rgba(255, 255, 255, 0.2);
        }
      `
const marginPartial = ({ direction }) =>
  direction === "next"
    ? css`
        margin-left: 10px;
        @media (min-width: ${breakpoints.tablet}px) {
          justify-content: flex-end;
          margin-left: 30px;
        }
      `
    : css`
        margin-right: 10px;
        @media (min-width: ${breakpoints.tablet}px) {
          margin-right: 30px;
          justify-content: flex-start;
        }
      `

const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: ${radius.large}px;
  transition: background ease-in-out 0.3s;
  ${activePartial}
  ${marginPartial}


  svg {
    height: 28vw;
    transition: fill ease-in-out 0.3s;

    path:first-of-type {
      stroke: rgba(255, 255, 255, 0.08);
      stroke-width: 0.15;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.15);

    svg {
      fill: rgba(0, 0, 0, 0.3);
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    cursor: pointer;
    border-radius: 20px;
    height: 65%;
    border-color: rgba(255, 255, 255, 0.1);
  }
`

Controls.propTypes = {
  update: PropTypes.func,
  effect: PropTypes.string,
}

Controls.defaultProps = {
  update: () => {},
  effect: ``,
}

export default Controls
