import React from "react"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 173.33 174.92"
    style={{
      zIndex: `100`,
      fill: `#fff`,
    }}
  >
    <path d="M27.18,58a2.07,2.07,0,0,0,.11,3.54C46.52,72.65,65.65,84,84.28,94c2.81,1.51,8.37-.46,11.91-2.28,10.91-5.59,21.46-11.9,34.95-19.5,0,8.73.7,14.86-.3,20.7-.51,2.94-3.55,6.25-6.39,7.87-9.4,5.34-17.49,10.2-26.66,15.07-6.68,4.29-12.16,4.28-19,.22C55.85,102.53,32.51,89.69,9.52,76.25c-12.13-7.08-12.19-25.9-.06-33.06C33.65,28.91,58,14.85,82.55,1.23c3.08-1.71,8.77-1.61,11.89.12,24.9,13.78,43.09,24.76,67.63,39.18,8.81,5.17,5.75,13.29,5.22,22.08a3.38,3.38,0,0,1-4.91,2.83c-2-1-3.7-2-5.39-2.93-22.83-13-39-23-62-35.72C91.7,25,86.1,24,83.16,25.62,67.33,34.14,36.53,51.85,27.18,58Z" />
    <path d="M2.5,104.32c6.63,3.62,57.34,32,75.83,43,6.08,3.62,11.08,3.69,17.17.1,21.68-12.76,71.69-40.84,74.95-42.51,5.6,17.41,3.61,22.29-11,30.56-21.27,12-42.55,24-63.55,36.46-6.42,3.81-11.7,4.14-18.17.32q-35.32-20.84-71-41C-4.72,124.71,1.76,104.92,2.5,104.32Z" />
  </svg>
)

export default Logo
