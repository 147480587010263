import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import styled from "@emotion/styled"
import { I18nProvider } from "@lingui/react"

import { UIContextProvider, UIContext } from "../../context/UIContext"
import { catalogs } from "../../i18n/categories"
import { globalStyles } from "../../utils/styles"

import Header from "../Header"
import Footer from "../Footer"
import HomeBG from "../HomeBG"
import Flashcard from "../FC"
import PageTransition from "../Transition"
import LangModal from "../Switcher/LangModal"

const Layout = ({ children, location }) => {
  return (
    <UIContextProvider location={location}>
      <UIContext.Consumer>
        {({ state }) => (
          <I18nProvider language={state.baseLang.code} catalogs={catalogs}>
            <Global styles={globalStyles} />
            <Header
              language={state.language}
              prevLang={state.prevLang}
              location={location.pathname}
            />
            <HomeBG location={location.pathname} />
            <PageTransition location={location}>
              <Main>{children}</Main>
              <Footer />
            </PageTransition>
            <Flashcard language={state.language} />
            <LangModal code={state.baseLang.code} />
          </I18nProvider>
        )}
      </UIContext.Consumer>
    </UIContextProvider>
  )
}

const Main = styled.main`
  z-index: 1;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
