import React from "react"

const FCIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4 18h3V5H4v13zM18 5v13h3V5h-3zM8 18h9V5H8v13z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export default FCIcon
