import React, { useState, useContext, useEffect } from "react"
import { getLang } from "../data/snippets"

// Create Context
const UIContext = React.createContext()

// Create Provider
const UIContextProvider = props => {
  const [state, setState] = useState({
    baseLang: {
      code: "en",
      name: "English",
    },
    language: props.location ? getLang(props.location.pathname) : getLang("/"),
    prevLang: props.location ? getLang(props.location.pathname) : getLang("/"),
    flashCardView: false,
    flashCards: [],
    heroHeight: 0,
  })

  useEffect(() => {
    if (props.location) {
      setState(state => ({
        ...state,
        path: props.location.pathname,
        language: getLang(props.location.pathname),
        prevLang: state.language,
      }))
    }
  }, [props.location])

  const value = React.useMemo(() => {
    return {
      state,
      setState,
    }
  }, [state])

  return <UIContext.Provider value={value}>{props.children}</UIContext.Provider>
}

// Create Context API

const updateUI = () => {
  const { setState } = useContext(UIContext)

  function toggleBaseLang(language) {
    setState(state => ({ ...state, baseLang: language }))
  }

  function setHeroHeight(height) {
    setState(state => ({ ...state, heroHeight: height }))
  }

  function loadFlashCards(data) {
    setState(state => ({
      ...state,
      flashCards: data.map(o => o.phrases).flat(1),
    }))
  }

  function toggleFlashCards() {
    setState(state => ({
      ...state,
      flashCardView: !state.flashCardView,
    }))
  }

  return {
    toggleBaseLang,
    setHeroHeight,
    loadFlashCards,
    toggleFlashCards,
  }
}

export { UIContext, UIContextProvider, updateUI }
