import React from "react"
import styled from "@emotion/styled"

import { speak } from "./speech"
import AudioIcon from "./AudioIcon"

const AudioButton = ({ string, language, audio = false, setAudio, index }) => {
  // Helper for prop drilling outside of onClick
  if (audio) {
    speak(string, language)
    setAudio(false)
  }

  const handleClick = e => {
    speak(string, language)
  }

  return (
    <Button className="naked-btn" onClick={handleClick} aria-label="Listen">
      <AudioIcon use={index > 0 ? true : false} aria-hidden="true" />
    </Button>
  )
}

const Button = styled.button`
  padding: 0;
  transition: all 0.3s ease;
  display: flex;
  margin-left: auto;
  width: 30px;

  svg,
  img {
    height: 20px;
    transition: transform ease 0.3s;
    margin: 0;
  }

  &:hover,
  &:active,
  &:focus {
    svg,
    img {
      transform: scale(1.2);
    }
  }
`

export default AudioButton
