import React, { useRef, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

import { animated as a, useTransition } from "react-spring"

import { updateUI } from "../../context/UIContext"
import { breakpoints } from "../../utils/styles"
import Globe from "./Globe"

const HomeBG = ({ location }) => {
  const backgroundRef = useRef(null)
  const { setHeroHeight } = updateUI()

  useLayoutEffect(() => {
    if (location === "/") {
      setHeroHeight(backgroundRef.current.clientHeight)
    }
  }, [location])

  const transitions = useTransition(location === "/" ? true : false, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Background key={key} style={props} ref={backgroundRef}>
              <Globe />
            </Background>
          )
      )}
    </>
  )
}

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 0.08; }
`

const Background = styled(a.div)`
  background: #3998e5;
  background: linear-gradient(to top right, #0089ff, #092384);
  // background: linear-gradient(to top right, #d2004d, #c58019);
  position: absolute;
  overflow: hidden;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 82vw;
    animation: ${fadeIn} ease 1.5s forwards;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    height: 100vh;
  }
`

HomeBG.propTypes = {
  location: PropTypes.string,
}

HomeBG.defaultProps = {
  location: ``,
}

export default HomeBG
