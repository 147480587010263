const getLang = pageLanguage => {
  switch (pageLanguage) {
    case "Arabic":
      return "ar-SA"
    case "Czech":
      return "cs-CS"
    case "Chinese":
      return "zh-CN"
    case "Danish":
      return "da-DK"
    case "Dutch":
      return "nl-NL"
    case "English":
      return "en-US"
    case "Finnish":
      return "fi-FI"
    case "French":
      return "fr-FR"
    case "German":
      return "de-DE"
    case "Greek":
      return "el-GR"
    case "Hebrew":
      return "he-IL"
    case "Hindi":
      return "hi-IN"
    case "Hungarian":
      return "hu-HU"
    case "Indonesian":
      return "id-ID"
    case "Italian":
      return "it-IT"
    case "Japanese":
      return "ja-JP"
    case "Korean":
      return "ko-KR"
    case "Norwegian":
      return "nb-NO"
    case "Polish":
      return "pl-PL"
    case "Portuguese":
      return "pt-PT"
    case "Romanian":
      return "ro-RO"
    case "Russian":
      return "ru-RU"
    case "Spanish":
      return "es-MX"
    case "Swedish":
      return "sv-SE"
    case "Thai":
      return "th-TH"
    case "Turkish":
      return "tr-TR"
    default:
      return "fr-FR"
  }
}

const voiceIndex = 0

const getVoices = () => {
  return new Promise(resolve => {
    let voices = speechSynthesis.getVoices()

    if (voices.length) {
      resolve(voices)
      return
    }
    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices()
      resolve(voices)
    }
  })
}

const chooseVoice = async lang => {
  const queryLang = getLang(lang)
  const voices = (await getVoices()).filter(voice => voice.lang === queryLang)

  return new Promise(resolve => {
    resolve(voices[voiceIndex])
  })
}

export const speak = async (text, lang) => {
  if (!speechSynthesis) {
    return
  }
  const message = new SpeechSynthesisUtterance(text)
  message.voice = await chooseVoice(lang)
  speechSynthesis.speak(message)
}
