import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"

import { UIContext } from "../../context/UIContext"
import Widgets from "./Widgets"
import { fonts, breakpoints, colors } from "../../utils/styles"

const Footer = () => {
  const { state } = useContext(UIContext)

  return (
    <>
      <Widgets color={state.language.color} path={state.path} />
      <FooterContainer>
        <LeftColumn color={state.language.color} path={state.path}>
          <Trans>Made by</Trans>{" "}
          <a className="attribution" href="https://twitter.com/mkdarshay">
            mkdarshay
          </a>{" "}
        </LeftColumn>
        <RightColumn>
          <Link to="/privacy-policy">
            <Trans>Privacy Policy</Trans>
          </Link>
          <Link to="/terms-of-use">
            <Trans>Terms of Use</Trans>
          </Link>
        </RightColumn>
      </FooterContainer>
    </>
  )
}

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 35px;
  background: #ffffff;
  border-top: 1px solid #eaeaea;
  font-family: ${fonts.heading};
  flex-direction: column;

  a {
    color: #101010;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 40px 50px;
    flex-direction: row;
  }
`

const LeftColumn = styled.div`
  margin-top: 35px;
  border-top: 1px solid #ececec;
  padding-top: 30px;

  .attribution {
    font-weight: bold;
    color: ${({ color, path }) => (path !== "/" ? color : colors.brand)};
  }

  @media (max-width: ${breakpoints.tablet}px) {
    text-align: center;
    order: 2;
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0;
    border: none;
    padding: 0;

    .attribution {
      margin-right: 30px;
    }
  }
`

const RightColumn = styled.div`
  margin-top: 15px;
  a {
    padding: 0 20px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 50px 0 0;
  }
`

export default Footer
