export const catalogs = {
  en: {
    languageData: {
      plurals: function(n, ord) {
        var s = String(n).split("."),
          v0 = !s[1],
          t0 = Number(s[0]) === n,
          n10 = t0 && s[0].slice(-1),
          n100 = t0 && s[0].slice(-2)
        if (ord)
          return n10 === 1 && n100 !== 11
            ? "one"
            : n10 === 2 && n100 !== 12
            ? "two"
            : n10 === 3 && n100 !== 13
            ? "few"
            : "other"
        return n === 1 && v0 ? "one" : "other"
      },
    },
    messages: {
      About: "About",
      Feedback: "Feedback",
      Features: "Features",
      Guide: "Guide",
      French: "French",
      Chinese: "Chinese",
      Italian: "Italian",
    },
  },
  zh: {
    languageData: {
      plurals: function(n, ord) {
        if (ord) return "other"
        return "other"
      },
    },
    messages: {
      // Nav and links
      Home: "主页",
      About: "关于",
      Feedback: "联络",
      Select: "选择",
      Features: "联络",
      Language: "语言",
      Languages: "语言",
      Guide: "指南",
      "Privacy Policy": "隐私政策",
      "Terms of Use": "使用条款",
      "Made by": "",
      // Languages
      Arabic: "阿拉伯语",
      Chinese: "中文",
      Croatian: "克罗地亚语",
      Czech: "捷克语",
      Danish: "丹麦语",
      Dutch: "荷兰语",
      English: "英语",
      Filipino: "菲律宾语",
      Finnish: "芬兰语",
      French: "法语",
      German: "德国的语言",
      Greek: "希腊语",
      Hebrew: "希伯来语",
      Hindi: "印地语",
      Hungarian: "匈牙利语",
      Indonesian: "印度尼西亚语",
      Italian: "意大利语",
      Japanese: "日语",
      Korean: "朝鲜语",
      Norwegian: "挪威语",
      Polish: "波兰语",
      Portuguese: "葡萄牙语",
      Romanian: "罗马尼亚语",
      Russian: "俄语",
      Serbian: "塞尔维亚语",
      Spanish: "西班牙语",
      Swahili: "斯瓦希里语",
      Swedish: "瑞典语",
      Tamil: "泰米尔语",
      Thai: "泰语",
      Turkish: "土耳其语",
      Ukrainian: "乌克兰语",
      Vietnamese: "越南语",
      // Buttons
      Flashcards: "抽认卡",
      "Hide Audio": "隐藏音频",
      "Show Audio": "显示音频",
      Unavailable: "不可用",
      Popular: "流行",
      Sort: "筛选",
      // Headings
      "Travel Anywhere": "随处旅行",
      Greetings: "问候",
      Replies: "回应",
      Dining: "餐饮",
      Etiquette: "礼貌",
      Directions: "方向",
      Opinions: "意见",
      Numbers: "数字",
      Conversation: "会话",
      Questions: "问题",
      "All Language Guides": "所有语言指南",
      "All Guides": "所有指南",
      "Study Guide": "学习指导",
      "Coming Soon...": "快来了...",
      // Flashcards
      Shuffle: "洗牌",
      Listen: "听",
      Close: "出口",
      "Flip Card": "翻身卡",
      "Prev / Next": "以前 / 下一个",
      // Phrases
      Hello: "你好",
      "How are you?": "你好吗?",
      "I'm well.": "我很好。",
      "My name is...": "我叫...",
      "I'm from...": "我来自...",
      "Nice to meet you.": "很高兴见到你。",
      Goodbye: "再见",
      Yes: "是",
      No: "不是 / 没有",
      "I don't know": "我不知道",
      OK: "好的",
      Please: "请",
      "Thank you": "谢谢",
      "No thank you": "不要谢谢",
      "You're welcome": "不客气",
      "Excuse me": "请问",
      "I'm sorry": "对不起",
      "Cheers!": "干杯!",
      "I would like...": "我想要...",
      "This one (👉).": "这个 (👉)。",
      "Another one, please.": "请另一个。",
      "I'm finished.": "我吃完了。",
      "The bill, please.": "我买单。",
      Stop: "停止",
      Go: "走",
      Right: "右",
      Left: "左",
      Straight: "直",
      "I'm lost.": "我迷路了。",
      Good: "好",
      Bad: "不好",
      "I like it.": "我喜欢这个。",
      "I don't like it.": "我不喜欢这个。",
      "Do you speak English?": "你会说英语吗?",
      "I don't understand.": "我不懂。",
      "Repeat that, please.": "你可以再說一遍嗎？",
      "Who?": "谁？",
      "When?": "什么时候？",
      "What?": "什么？",
      "Where?": "哪里？",
      "How much?": "多少？",
      "Where is the bathroom?": "洗手间在哪里？",
      "Can you help me?": "你可以帮我吗？",
      "1": "一",
      "2": "二",
      "3": "三",
      "4": "四",
      "5": "五",
      "6": "六",
      "7": "七",
      "8": "八",
      "9": "九",
      "10": "十",
    },
  },
}
