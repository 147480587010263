import React from "react"
import speakerSVG from "./speaker.svg"

const AudioIcon = ({ use, inline, ...props }) => {
  if (inline) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" {...props}>
        {!use ? (
          <symbol id="audio-icon" viewBox="0 0 45 45">
            <path
              fill="#8899a6"
              d="M2.5 12.5S0 12.5 0 15v15c0 2.5 2.5 2.5 2.5 2.5H10l10 10s1.25 1.25 2.5 1.25h1.25s1.25 0 1.25-1.25v-40s0-1.25-1.25-1.25H22.5C21.25 1.25 20 2.5 20 2.5l-10 10H2.5z"
            />
            <path
              fill="#ccd6dd"
              d="M10 32.5l10 10s1.25 1.25 2.5 1.25h1.25s1.25 0 1.25-1.25v-40s0-1.25-1.25-1.25H22.5C21.25 1.25 20 2.5 20 2.5l-10 10v20z"
            />
            <path
              fill="#8899a6"
              d="M36.25 40.024a1.181 1.181 0 0 1-.767-2.083 20.158 20.158 0 0 0 7.085-15.362A20.155 20.155 0 0 0 35.48 7.216a1.183 1.183 0 1 1 1.535-1.8 22.515 22.515 0 0 1 7.917 17.163 22.515 22.515 0 0 1-7.916 17.161 1.171 1.171 0 0 1-.767.284"
            />
            <path
              fill="#8899a6"
              d="M32.837 36.199a1.16 1.16 0 0 1-.74-2.056A15.047 15.047 0 0 0 37.59 22.5c0-4.466-1.965-8.679-5.389-11.556a1.161 1.161 0 0 1 1.495-1.778A17.38 17.38 0 0 1 39.911 22.5a17.353 17.353 0 0 1-6.337 13.433 1.147 1.147 0 0 1-.738.266"
            />
            <path
              fill="#8899a6"
              d="M29.636 32.449a1.25 1.25 0 0 1-.795-2.216A9.971 9.971 0 0 0 32.5 22.5a9.96 9.96 0 0 0-3.735-7.795 1.25 1.25 0 1 1 1.567-1.947A12.45 12.45 0 0 1 35 22.5a12.465 12.465 0 0 1-4.571 9.664 1.238 1.238 0 0 1-.793.285"
            />
          </symbol>
        ) : null}
        <use href="#audio-icon" />
      </svg>
    )
  } else {
    return <img src={speakerSVG} alt="Speaker Icon" />
  }
}

export default AudioIcon
