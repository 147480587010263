import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"
import {
  useTransition,
  useTrail,
  useChain,
  useSpring,
  animated as a,
} from "react-spring"

import Switcher from "../Switcher"
import LanguageList from "../Footer/LanguageList"
import { CloseIcon, BackIcon } from "../../assets/icons"
import { reverse } from "../../utils/helper"

const config = { mass: 5, tension: 2000, friction: 200 }
const menuItems = [
  { label: `Home`, url: `/` },
  { label: `About`, url: `/about` },
  { label: `Feedback`, url: `/feedback` },
  { label: `Languages`, type: `button` },
]

// Root Component

const MobileNav = ({ show, setMobile }) => {
  const [hideLangs, setLangs] = useState(true)

  const transRef = useRef()
  const transitions = useTransition(show, null, {
    from: { opacity: 0, transform: `translateY(-10px)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0, transform: `translateY(-10px)` },
    ref: transRef,
  })

  const trailRef = useRef()
  const trail = useTrail(menuItems.length, {
    config,
    opacity: show ? 1 : 0,
    x: show ? 0 : 20,
    height: show ? 60 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    ref: trailRef,
  })

  useChain(show ? [transRef, trailRef] : [trailRef, transRef], [
    0,
    show ? 0.05 : 0.25,
  ])

  function resetView() {
    setLangs(true)
    setMobile(false)
  }

  const exitLink = () => {
    setTimeout(() => {
      resetView()
    }, 300)
  }

  const springProps = useSpring({
    opacity: hideLangs ? 0 : 1,
  })

  const revealProps = useSpring({
    to: reverse(hideLangs, [
      { visibility: hideLangs ? "visible" : "hidden" },
      { opacity: hideLangs ? 1 : 0 },
    ]),
    config: { friction: 15 },
  })

  const unrevealProps = useSpring({
    to: reverse(!hideLangs, [
      { visibility: !hideLangs ? "visible" : "hidden" },
      { opacity: !hideLangs ? 1 : 0 },
    ]),
    config: { friction: 20 },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <NavOverlay
              style={props}
              key={key}
              aria-expanded={show ? "true" : "false"}
            >
              <NavControls>
                <Back
                  className="naked-btn"
                  style={springProps}
                  aria-label="Back to main menu"
                  onClick={() => setLangs(true)}
                >
                  <BackIcon />
                </Back>
                <Close
                  className="naked-btn"
                  aria-label="Close menu"
                  onClick={() => resetView()}
                >
                  <CloseIcon />
                </Close>
              </NavControls>

              {/* Main Menu Container */}

              <a.div style={revealProps}>
                <ul aria-label="Mobile Navigation" role="menu">
                  {trail.map(({ x, height, ...rest }, index) => (
                    <NavItem
                      key={menuItems[index].label}
                      style={{
                        ...rest,
                        transform: x.interpolate(
                          x => `translate3d(0,${x}px,0)`
                        ),
                      }}
                    >
                      <a.div style={{ height, overflow: "hidden" }}>
                        {menuItems[index].type !== "button" ? (
                          <NavLink
                            to={menuItems[index].url}
                            onClick={() => exitLink()}
                          >
                            <Trans id={menuItems[index].label} />
                          </NavLink>
                        ) : (
                          <LanguageMenu
                            className="naked-btn"
                            aria-label="View Languages"
                            onClick={() => setLangs(false)}
                          >
                            <Trans id={menuItems[index].label} />{" "}
                            <span>&#9662;</span>
                          </LanguageMenu>
                        )}
                      </a.div>
                    </NavItem>
                  ))}
                </ul>
                <Switcher type="mobile" exit={true} />
              </a.div>

              {/* Language Container */}

              <LanguageContainer style={unrevealProps}>
                <h4>
                  <Trans>All Guides</Trans>
                </h4>
                <LanguageList type="mobile" color="#fff" exit={exitLink} />
              </LanguageContainer>
            </NavOverlay>
          )
      )}
    </>
  )
}

const LanguageContainer = styled(a.div)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  top: 100px;
  padding: 0 40px 20px;
  overflow-y: scroll;

  h4 {
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    font-size: 20px;
  }
`

const NavOverlay = styled(a.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.9);
`

const NavControls = styled.div``

const Back = styled(a.button)`
  position: absolute;
  top: 15px;
  left: 15px;

  svg {
    fill: #fff;
    height: 55px;
    width: auto;
    display: flex;
  }
`

const Close = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;

  svg {
    fill: #fff;
    height: 55px;
    width: auto;
    display: flex;
  }
`

const LanguageMenu = styled.button`
  padding: 0 20px;
  margin: 0;
  position: relative;
  color: #fff;

  span {
    font-size: 30px;
    margin-left: 5px;
  }
`

const NavLink = styled(Link)`
  padding: 10px 20px;
  margin: 0;
  position: relative;
`

const NavItem = styled.li`
  position: relative;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 2.5em;
  font-family: Avenir Next;
  will-change: transform, opacity;
  overflow: hidden;

  @media screen and (min-width: 960px) {
    font-size: 3.8em;
  }
`

MobileNav.propTypes = {
  show: PropTypes.bool,
  setMobile: PropTypes.func,
}

MobileNav.defaultProps = {
  show: false,
  setMobile: () => {},
}

export default MobileNav
