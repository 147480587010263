import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Trans } from "@lingui/macro"

import { breakpoints } from "../../utils/styles"
import LanguageList from "./LanguageList"

const Widgets = ({ color, path }) => {
  return (
    <FooterWidgets path={path}>
      <h3>
        <Trans>All Language Guides</Trans>
      </h3>
      <LanguageList color={color} />
    </FooterWidgets>
  )
}

const FooterWidgets = styled.div`
  display: ${({ path }) => (path === "/" ? "none" : "block")};
  padding: 40px 20px;
  background: #f7f7f7;

  h3 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 80px 50px;

    h3 {
      text-align: center;
      margin-bottom: 80px;
      font-size: 36px;
    }
  }
`

Widgets.propTypes = {
  path: PropTypes.string,
  color: PropTypes.string,
}

Widgets.defaultProps = {
  path: ``,
  color: ``,
}

export default Widgets
